/* Menu customization */
.leftMenu a {
	text-decoration: none;
	color: black;
}

body {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.fullWitdthMenuItemTitle {
	width: 100%;
	background: #2b3469;
	height: 3em;
	text-align: center;
}

.fullWitdthMenuItemTitle span {
	font-family: 'Hoefler Text', Georgia, 'Times New Roman', serif;
	font-size: 1em;
	letter-spacing: .2em;
	line-height: 3em;
	color: whitesmoke;
	font-weight: bold;
	text-transform: uppercase;
}

.drawerScrollbar>div::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.drawerScrollbar>div {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
	-webkit-box-shadow: 1px 0px 5px 0px rgba(204, 202, 204, 1);
	-moz-box-shadow: 1px 0px 5px 0px rgba(204, 202, 204, 1);
	box-shadow: 1px 0px 5px 0px rgba(204, 202, 204, 1);
}

.MuiToolbar-root {
	min-height: 55px !important;
}


/* Date Range Input Override Styles */
.react-daterange-picker__inputGroup,
.react-daterange-picker__range-divider,
.react-date-picker__inputGroup {
	display: none;
}

.react-daterange-picker__clear-button {
	width: 40px !important
}

.react-date-picker__calendar--open {
	top: -100% !important;
	bottom: unset !important;
	z-index: 100 !important;
}

.react-date-picker__button, .react-daterange-picker__button {
	width: 100%
}


/* Tables Customization */
.mainSection thead tr th:last-child div {
	max-width: 160px;
	width: 40px;
	margin: auto;
}

table tbody tr:nth-of-type(odd) {
	background-color: #f5f5f5 !important
}


/* Main Quotes Status Filters customization */
@media only screen and (max-width: 1460px) {
	.statusFilterLabel span {
		font-size: 14px;
	}
}

@media only screen and (max-width: 1460px) {
	.statusFilterLabel span {
		font-size: 14px;
	}

	.statusFiltersSection {
		flex: 1 !important;
	}
}


/* Due & Paid Quotes resize CSS rules */
@media only screen and (max-width: 1200px) {

	.dueQuotesSection tr td,
	.dueQuotesSection th {
		padding: 0
	}

	.paidQuotesSection tr td,
	.paidQuotesSection th {
		padding: 0
	}

	.dueQuotesSection .actionButtons,
	.paidQuotesSection .actionButtons {
		min-width: 10px !important;
		flex-direction: column;
	}

}

@media only screen and (max-width: 1100px) {
	.quotesSearchFilter {
		min-width: 75% !important;
		margin-bottom: 8px !important;
	}
}

/* 
	Main Section Tables resize CSS rules
	Quotes, Due Quotes, Paid Quotes, Leads
*/
@media only screen and (max-width: 900px) {

	.mainSection thead {
		display: none !important
	}

	.mainSection table tbody tr {
		border-top: 0.5px solid #aeafb0 !important;
		display: flex !important;
		flex-direction: column;
	}

	.mainSection table tbody tr td {
		width: 100% !important;
		display: flex !important;
		flex: 1 !important;
		padding-right: 10%;
	}

	.mainSection table tbody tr td::before {
		display: flex;
		flex-direction: row;
		flex: 1;
		font-weight: bold;
		padding-left: 10%;
	}


	/* Quotes Table Columns */
	.quotesSection table tbody tr td:nth-child(1)::before {
		content: "ID";
	}

	.quotesSection table tbody tr td:nth-child(2)::before {
		content: "Title";
		margin-right: 30px;
	}

	.quotesSection table tbody tr td:nth-child(3)::before {
		content: "Published";
	}

	.quotesSection table tbody tr td:nth-child(4)::before {
		content: "Confirmed";
	}

	.quotesSection table tbody tr td:nth-child(5)::before {
		content: "Actions";
	}




	/* In progress Quote Payments Table Columns */
	.inProgressPaymentsSection table tbody tr td:nth-child(1)::before {
		content: "Golf Vacation Item Id";
	}

	.inProgressPaymentsSection table tbody tr td:nth-child(2)::before {
		content: "Title";
		margin-right: 30px;
	}

	.inProgressPaymentsSection table tbody tr td:nth-child(3)::before {
		content: "Date of Arrival";
	}

	.inProgressPaymentsSection table tbody tr td:nth-child(4)::before {
		content: "Date of Departure";
	}

	.inProgressPaymentsSection table tbody tr td:nth-child(5)::before {
		content: "Paid amount";
	}

	.inProgressPaymentsSection table tbody tr td:nth-child(6)::before {
		content: "Total cost";
	}

	.inProgressPaymentsSection table tbody tr td:nth-child(7)::before {
		content: "Total price";
	}

	.inProgressPaymentsSection table tbody tr td:nth-child(8)::before {
		content: "Actions";
	}



	/* Due Quotes Table Columns */
	.dueQuotesSection table tbody tr td:nth-child(1)::before {
		content: "ID";
	}

	.dueQuotesSection table tbody tr td:nth-child(2)::before {
		content: "Title";
	}

	.dueQuotesSection table tbody tr td:nth-child(3)::before {
		content: "Date of arrival";
	}

	.dueQuotesSection table tbody tr td:nth-child(4)::before {
		content: "Days left";
	}

	.dueQuotesSection table tbody tr td:nth-child(5)::before {
		content: "No. of golfers";
	}

	.dueQuotesSection table tbody tr td:nth-child(6)::before {
		content: "Paid Amount";
	}

	.dueQuotesSection table tbody tr td:nth-child(7)::before {
		content: "Total to be paid";
	}

	.dueQuotesSection table tbody tr td:nth-child(8)::before {
		content: "Per golfer";
	}

	.dueQuotesSection table tbody tr td:nth-child(9)::before {
		content: "Actions";
	}

	.dueQuotesSection .actionButtons {
		min-width: 160px !important;
		flex-direction: row;
	}

	.dueQuotesSection tr td,
	.dueQuotesSection th {
		padding: 1rem
	}



	/* Paid Quotes Table Columns */
	.paidQuotesSection table tbody tr td:nth-child(1)::before {
		content: "Golf Vacation Item Id";
	}

	.paidQuotesSection table tbody tr td:nth-child(2)::before {
		content: "Title";
	}

	.paidQuotesSection table tbody tr td:nth-child(3)::before {
		content: "Date of arrival";
	}

	.paidQuotesSection table tbody tr td:nth-child(4)::before {
		content: "Days left";
	}

	.paidQuotesSection table tbody tr td:nth-child(5)::before {
		content: "No. of golfers";
	}

	.paidQuotesSection table tbody tr td:nth-child(6)::before {
		content: "Paid Amount";
	}

	.paidQuotesSection table tbody tr td:nth-child(7)::before {
		content: "Total";
	}

	.paidQuotesSection table tbody tr td:nth-child(8)::before {
		content: "Voucher sent";
	}

	.paidQuotesSection table tbody tr td:nth-child(9)::before {
		content: "Actions";
	}

	.paidQuotesSection .actionButtons {
		min-width: 160px !important;
		flex-direction: row;
	}

	.paidQuotesSection tr td,
	.paidQuotesSection th {
		padding: 1rem
	}



	/* Leads Table Columns */
	.leadsSection table tbody tr td:nth-child(1)::before {
		content: "ID";
	}

	.leadsSection table tbody tr td:nth-child(2)::before {
		content: "Source";
	}

	.leadsSection table tbody tr td:nth-child(3)::before {
		content: "Device";
	}

	.leadsSection table tbody tr td:nth-child(4)::before {
		content: "Name";
	}

	.leadsSection table tbody tr td:nth-child(5)::before {
		content: "Lead Date";
	}

	.leadsSection table tbody tr td:nth-child(6)::before {
		content: "Email";
	}

	.leadsSection table tbody tr td:nth-child(7)::before {
		content: "Phone";
	}

	.leadsSection table tbody tr td:nth-child(8)::before {
		content: "Message";
	}

	.leadsSection table tbody tr td:nth-child(9)::before {
		content: "Actions";
	}

	.leadsSection table tbody tr td:nth-child(6) {
		padding: 1rem !important;
	}




	/* Orders Table Columns */
	.ordersSection table tbody tr td:nth-child(1)::before {
		content: "ID";
	}

	.ordersSection table tbody tr td:nth-child(2)::before {
		content: "Username";
	}

	.ordersSection table tbody tr td:nth-child(3)::before {
		content: "Email";
	}

	.ordersSection table tbody tr td:nth-child(4)::before {
		content: "First Name";
	}

	.ordersSection table tbody tr td:nth-child(5)::before {
		content: "Last Name";
	}

	.ordersSection table tbody tr td:nth-child(6)::before {
		content: "Title";
	}

	.ordersSection table tbody tr td:nth-child(7)::before {
		content: "Total";
	}

	.ordersSection table tbody tr td:nth-child(8)::before {
		content: "Paid";
	}

	.ordersSection table tbody tr td:nth-child(9)::before {
		content: "Actions";
	}

	.ordersSection table tbody tr td:last-child div {
		justify-content: flex-end !important;
	}
}